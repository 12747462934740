import React, { FC } from 'react';
import { graphql, Link } from 'gatsby';
import { kebabCase } from 'lodash';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import TextHeader from '../components/TextHeader';

import { Category } from '../types';

const CategoryCard: FC<{ category: Partial<Category> }> = ({ category }) => (
    <li className="relative">
        <div className="block w-full overflow-hidden bg-gray-100 rounded-lg group aspect-video">
            <Link to={kebabCase(category.categoryName)}>
                <img
                    src={category.categoryPreview}
                    alt={category.categoryName}
                    className="object-cover w-full h-full"
                />
                <button
                    type="button"
                    className="absolute inset-0 focus:outline-none"
                >
                    <span className="sr-only">
                        View ${category.categoryName} category
                    </span>
                </button>
            </Link>
        </div>
        <Link
            to={kebabCase(category.categoryName)}
            className="block mt-2 text-base font-medium text-gray-900 truncate"
        >
            {category.categoryName}
        </Link>
    </li>
);

const Categories = ({ data }) => (
    <Layout {...data.site.siteMetadata}>
        <Seo
            pageTitle="All Categories"
            description="See all the filthy scat videos available on FemScat at KinkyScat.com!"
            {...data.site.siteMetadata}
        />

        <TextHeader title="All Categories" />
        <div className="space-y-12">
            <ul
                role="list"
                className="mt-12 space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8"
            >
                {data.allCategoriesJson.nodes.map((node: Partial<Category>) => (
                    <CategoryCard key={`${node.categoryId}`} category={node} />
                ))}
            </ul>
        </div>
    </Layout>
);

export default Categories;

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
                mainSiteUrl
                social {
                    twitter
                    reddit
                    rss
                }
            }
        }
        allCategoriesJson {
            nodes {
                categoryId
                categoryName
                categoryPreview
            }
        }
    }
`;
